<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <div>
          <v-img :src="`/static/image/affiliate/banner/${currentLanguage}/banner-1.png`" class="hidden-sm-and-down"></v-img>
          <v-img :src="`/static/image/affiliate/banner/${currentLanguage}/mbanner-1.png`" class="hidden-md-and-up"></v-img>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="affiliate-secondary-banner-container">
          <v-img :src="'/static/image/affiliate/beouragent.png'" aspect-ratio="1.77"></v-img>
          <div class="affiliate-secondary-banner-content">
            <label class="d-inline-block title primary--text font-weight-bold">{{ $t(`label.beOurAgent`) }}</label>
            <label class="d-block">{{ $t(`label.registerAnAccountToBecomeAnAgent_1`) }}</label>
            <app-button :title="$t(`label.moreDetail`)" :action="goToAffilaitePromotion"></app-button>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="affiliate-secondary-banner-container">
          <v-img :src="'/static/image/affiliate/beoursenioragent.png'" aspect-ratio="1.77"></v-img>
          <div class="affiliate-secondary-banner-content">
            <label class="d-inline-block title primary--text font-weight-bold">{{ $t(`label.beOurSeniorAgent`) }}</label>
            <label class="d-block">{{ $t(`label.levelUpYourGameEarnHigherCommissions_1`) }}</label>
            <label class="d-block">{{ $t(`label.levelUpYourGameEarnHigherCommissions_2`) }}</label>
            <app-button :title="$t(`label.moreDetail`)" :action="openAffilaiteTncDialog"></app-button>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <div>
          <app-play-with-us
            :buttonAction="isLoggedIn ? goToAffilateProfilePage : goToRegisterPage"
            :buttonTitle="isLoggedIn ? $t(`label.startReferFriend`) : $t(`label.registerNow`)"
          ></app-play-with-us>
        </div>
      </v-col>
    </v-row>

    <app-dialog :dialogShow="affiliateTncDialog.show" :max-width="1100" :title="affiliateTncDialog.title" :closeAction="affiliateTncDialog.dialogXButton">
      <v-container class="fill-height px-0">
        <div class="affilaitTncContainer">
          <div v-html="affiliateTncContent"></div>
        </div>
      </v-container>
    </app-dialog>
  </div>
</template>

<script>
import { DevicePlatform } from '@/constants/enums'
import axios from 'axios'
import { locale, uiHelper } from '@/util'
import { ROUTE_NAME } from '@/constants/route.constants'
import AppPlayWithUs from '@/components/affiliate/PlayWithUs.vue'
export default {
  name: 'affiliateHome',
  components: {
    AppPlayWithUs
  },
  data: () => ({
    currentLanguage: uiHelper.getLanguage(),
    affiliateTncContent: null,
    affiliateTncDialog: {
      show: false,
      title: locale.getMessage(`label.affilaiteProgram`),
      dialogXButton: function() {}
    }
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    }
  },
  methods: {
    goToAffilaitePromotion() {
      this.$router.push({
        name: ROUTE_NAME.PROMOTION,
        params: {
          bonusId: 'AffiliateReferal'
        }
      })
    },
    loadAffilaiteTncHtml() {
      let htmlPath = `${process.env.BASE_URL}static/html/other/${uiHelper.getLanguage()}/affiliate-tnc.html`
      axios({
        method: 'get',
        url: htmlPath
      }).then(result => {
        this.affiliateTncContent = result.data
      })
    },
    openAffilaiteTncDialog() {
      this.loadAffilaiteTncHtml()
      this.affiliateTncDialog.show = true
      this.affiliateTncDialog.dialogXButton = this.closeAffilaiteTncDialog
    },
    closeAffilaiteTncDialog() {
      this.affiliateTncDialog.show = false
    },
    goToAffilateProfilePage() {
      this.$router.push({
        name: ROUTE_NAME.AFFILIATE_LINK
      })
    },
    goToDepositPage() {
      if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
        let routeData = this.$router.resolve({
          name: ROUTE_NAME.DEPOSIT
        })
        window.open(routeData.href)
      } else {
        this.$router.push({
          name: ROUTE_NAME.DEPOSIT
        })
      }
    },
    goToRegisterPage() {
      this.$router.push({
        name: ROUTE_NAME.REGISTER,
        params: { lang: this.$route.params.lang }
      })
    },
    goToLoginPage() {
      this.$router.push({
        name: ROUTE_NAME.LOGIN,
        params: { lang: this.$route.params.lang }
      })
    },
    openRegisterDialog() {
      this.$parent.openRegisterDialog()
    }
  }
}
</script>
<style lang="scss">
.affiliate-secondary-banner-container {
  position: relative;
  .affiliate-secondary-banner-content {
    width: 40%;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-left: 60px;
    transform: translate(0%, -50%);
    .title {
      border-bottom: 4px solid var(--v-primary-base);
      margin: 0;
      margin-bottom: 5px;
      font-size: 18px;
    }
  }
}

@media (max-width: 1263px) {
  .affiliate-secondary-banner-container .affiliate-secondary-banner-content .title {
    font-size: 18px !important;
  }
}

@media (max-width: 599px) {
  .affiliate-secondary-banner-container .affiliate-secondary-banner-content {
    width: 45%;
    margin-left: 30px;
  }
  .affiliate-secondary-banner-container .affiliate-secondary-banner-content .title {
    font-size: 16px !important;
  }
}

@media (max-width: 425px) {
  .affiliate-secondary-banner-container {
    height: 230px;
    .v-image {
      height: 100%;
    }
  }
}

.affilaitTncContainer {
  height: 80%;
  max-height: 500px;
  overflow-y: auto;
}
</style>
